import styles from './nav.module.scss';
import { Navbar, Nav } from 'react-bootstrap';
import { CompanyInterface } from '../models/company';
/**
 * The approach used in this component shows how to built a sign in and sign out
 * component that works on pages which support both client and server side
 * rendering, and avoids any flash incorrect content on initial page load.
 **/

type NavProps = {
  company?: CompanyInterface;
  pageTitle?: string;
};

const Navigation = (props: NavProps) => {
  const { company, pageTitle } = props;
  const legalName = company ? company.legalName : '';
  const logo =
    company && company._id ? company.customization.logo : '/canimal_logo.png';
  const companyStyles = {
    borderBottom: '4px solid #000000',
    color: '#000000'
  };

  return (
    <Navbar style={companyStyles}>
      <Navbar.Brand href="/" className={styles.navBrand}>
        <img src={logo} alt={legalName} />
        <div className="navbar-brand-copy align-bottom">
          <span className="legalName">Custom Cans</span>
          <br />
          <span className="title">{pageTitle}</span>
        </div>
        <div className="clearFix" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto"></Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
