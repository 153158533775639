import { namespaceConfig } from 'fast-redux';

const DEFAULT_STATE = {
  hasError: false,
  hasSuccess: false,
  error: null,
  message: null,
  successComponent: null,
  closeCallback: null
};
export const { action, getState: getRequestState } = namespaceConfig(
  'request',
  DEFAULT_STATE
);

export const showError = action('showError', (state, error) => ({
  ...state,
  hasError: true,
  hasSuccess: false,
  error
}));

export const showSuccess = action(
  'showSuccess',
  (state, message, successComponent, closeCallback) => ({
    ...state,
    hasError: false,
    hasSuccess: true,
    message,
    successComponent,
    closeCallback
  })
);

export const hideError = action('hideError', (state) => ({
  ...state,
  hasError: false,
  error: null
}));

export const hideSuccess = action('hideSuccess', (state) => ({
  ...state,
  hasSuccess: false,
  message: null,
  successComponent: null
}));
